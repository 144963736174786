<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <el-button type="text" @click="showSearch = !showSearch"><font-awesome-icon icon="search" /></el-button>
      </div>
      <div class="card-body" v-if="showSearch">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Transaction Date">
                <el-date-picker v-model="search.date" type="daterange" size="small" :picker-options="datePickerOptions"
                  class="w-full" range-separator="To" start-placeholder="Start date" end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <!-- <el-input v-if="search.search_by test === 'invoice_number'" clearable size="small" type="text" v-model="search.search_keyword" -->
              <el-input clearable size="small" type="text" v-model="search.search_keyword"
                  placeholder="Input search keyword" class="mr-2" />
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Transaction Status">
                <el-select size="small" v-model="search.status_filter" class="mr-2">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value"
                    :label="item.text" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2">
            &nbsp;
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Payment Vendor">
                <el-select size="small" v-model="search.payment_vendor_filter" class="mr-2">
                  <el-option v-for="(item, index) in payment_vendor_filter_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search" />
          Search</el-button>
        <el-button type="primary" @click="handlerDownloadBtn" size="small"><font-awesome-icon icon="download" />
          Download</el-button>
      </div>
      <div class="table-container">
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="25%">Transaction Date</th>
                <th width="25%">Invoice Number</th>
                <th width="20%">User Name</th>
                <th width="20%">User Email</th>
                <th width="10%">Amount</th>
                <th width="10%">Discount</th>
                <th width="10%">Admin Fee</th>
                <th>Payment Vendor</th>
                <th>Payment Method</th>
                <th width="20%" class="child2-sticky">Status</th>
                <th class="child1-sticky">Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td class="table-sticky-left">{{ item.created_at_str }}</td>
                <td class="table-sticky-left">{{ item.invoice_number }}</td>
                <td>{{ item.user_full_name }}</td>
                <td>{{ item.user_email }}</td>
                <td>{{ item.amount_str }}</td>
                <td>{{ item.discount_str }}</td>
                <td>{{ item.admin_fee_str }}</td>
                <td>{{ item.payment_vendor_str }}</td>
                <td>{{ item.pm_payment_name }}</td>
                <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.status_color_str">{{
                  item.status_str }}</span></td>
                <td class="text-nowrap text-muted child1-sticky">
                  <button @click="viewDetailTransaction(item)" title="View Detail" type="button"
                    class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
                </td>
                <td class="text-nowrap text-muted child1-sticky">
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
    <!-- MODAL TRANSACTION DETAIL-->
    <b-modal size="xl" v-model="showDetailTransaction" :title="'Transaction #' + selected_transaction.invoice_number"
      hide-footer>
      <el-skeleton :loading="loading_detail" :rows="6" animated />
      <el-descriptions v-if="!loading_detail" class="margin-top mb-3" title="Order Information" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Transaction Date
          </template>
          {{ selected_transaction.created_at_str }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            Payment Ref
          </template>
          {{ selected_transaction.details?.[0]?.payment_ref || '-' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            User Name
          </template>
          {{ selected_transaction.user_full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            Phone Number
          </template>
          {{ selected_transaction.user_phone_number }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            Email
          </template>
          {{ selected_transaction.user_email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-wallet"></i>
            Payment Method
          </template>
          {{ selected_transaction.pm_payment_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-finished"></i>
            Payment Status
          </template>
          <span class="tag" :class="selected_transaction.status_color_str">{{ selected_transaction.status_str }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-c-scale-to-original"></i>
            Virtual Account Number
          </template>
          {{ selected_transaction.virtual_account_number || '-' }}
        </el-descriptions-item>
        <!-- del -->
        <el-descriptions-item v-if="selected_transaction.payment_vendor_str === 'Dipay' || selected_transaction.payment_vendor_str === 'Espay'  || selected_transaction.payment_vendor_str === 'Motion Pay'">
          <template slot="label">
            <i class="el-icon-finished"></i>
            Payment Status Vendor
          </template>
          <!-- <button v-b-toggle.check-dipay-status @click="checkDipayStatus(selected_transaction)" title="View Detail Refund" type="button" class="btn btn-icon btn-flickr btn-sm"> Check Dipay Status </button> -->
          <el-button @click="toggleCollapse" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Check Dipay Status</el-button>
          <div v-if="!collapseOpened" class="tooltip show tooltip-inner tooltip-container" align="center" role="tooltip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner">
                Click this
              <i class="el-icon-top"></i>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item v-if="selected_transaction.payment_vendor_str === 'Dipay' || currentRole">
          <template slot="label">
            <i class="el-icon-finished"></i>
            Refund
          </template>
            <!-- NORMAL FLOW REFUND-->
          <el-button v-if="!hasRefundCookies && currentRole" :disabled="isRefundDisabled(selected_transaction) || !isBothCollapseOpened" @click="confirm()" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Refund</el-button>
            <!-- COOKIES FLOW REFUND -->
          <el-button v-if="hasRefundCookies && currentRole" :disabled="isRefundDisabled(selected_transaction) || !isBothCollapseOpened" @click="confirmCookiesRefund()" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Refund</el-button>
        </el-descriptions-item>
        <el-descriptions-item>
        </el-descriptions-item>
        <el-descriptions-item v-if="currentRole">
          <template slot="label">
            <i class="el-icon-finished"></i>
            Refund Manual
          </template>
          <el-button :disabled="isRefundDisabled(selected_transaction) || !isBothCollapseOpened || displayRefundRequest"  @click="showModalAttachment()" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Refund Request</el-button>
        </el-descriptions-item>
      </el-descriptions>
       <!-- OTP Modal refund -->
      <el-dialog :visible.sync="dialogVisibleOtp" title="Confirmation" width="30%">
        <p>Are you sure you want to refund this product?</p>
        <el-form>
        <el-form-item label="Select Venteny Name" prop="selectedOption">
          <el-select v-model="selectedOption" placeholder="Select" size="small" class="mr-2">
            <el-option
              v-for="option in selectOptions"
              :key="option.company_id"
              :label="option.name"
              :value="option.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleOtp = false">Cancel</el-button>
          <el-button type="primary" @click="confirm">Confirm</el-button>
        </span>
      </el-dialog>
      <!-- NORMAL FLOW REFUND -->
      <el-dialog :visible.sync="otpModalVisible" title="Enter OTP refund" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <!-- Step 1: Select Type -->
          <el-form-item v-if="tab1" label="Select Type" prop="type" required>
            <el-select v-model="otpForm.type" class="mr-2" style="width: 250px">
              <el-option v-for="(item, index) in type_options" :key="index" :value="item.value" :label="item.text"/>
            </el-select>
          </el-form-item>
          <!-- Step 2: OTP & Reason -->
          <template v-else>
          <el-form-item label="OTP" prop="otp" required>
            <el-input 
              v-model="otpForm.otp" 
              placeholder="Enter OTP" 
              :maxlength="6"            
              :show-password="true"    
              @input="formatOTP"       
            ></el-input>
          </el-form-item>
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeOTPModal">Cancel</el-button>
          <el-button v-if="tab1"  :disabled="otpForm.type === ''" type="primary" @click="confirm1(selected_transaction)">Next</el-button>
          <el-button v-else :disabled="otpForm.otp === '' || otpForm.reason === ''" type="primary" @click="verifyOTP(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
      <!-- COOKIES FLOW REFUND -->
       <el-dialog :visible.sync="otpModalVisibleCookies" title="Input Reason" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeOTPModal">Cancel</el-button>
          <el-button :disabled="otpForm.reason === ''" type="primary" @click="verifyOTPRefundCookies(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
       <!-- OTP Modal repurchase -->
      <el-dialog :visible.sync="dialogVisibleOtpRepurchase" title="Confirmation" width="30%">
        <p>Are you sure you want to repurchase this product?</p>
        <el-form>
        <el-form-item label="Select Venteny Name" prop="selectedOption">
          <el-select v-model="selectedOption" placeholder="Select" size="small" class="mr-2">
            <el-option
              v-for="option in selectOptions"
              :key="option.company_id"
              :label="option.name"
              :value="option.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleOtpRepurchase = false">Cancel</el-button>
          <el-button type="primary" @click="confirmRepurchase">Confirm</el-button>
        </span>
      </el-dialog>
      <!-- NORMAL FLOW-->
      <el-dialog :visible.sync="otpModalVisibleRepurchase" title="Enter OTP" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <!-- Step 1: Select Type -->
          <el-form-item v-if="tab1" label="Select Type" prop="type" required>
            <el-select v-model="otpForm.type" class="mr-2" style="width: 250px">
              <el-option v-for="(item, index) in type_options" :key="index" :value="item.value" :label="item.text"/>
            </el-select>
          </el-form-item>
          <!-- Step 2: OTP & Reason -->
          <template v-else>
          <el-form-item label="OTP" prop="otp" required>
            <el-input 
              v-model="otpForm.otp" 
              placeholder="Enter OTP" 
              :maxlength="6"            
              :show-password="true"     
              @input="formatOTP"       
            ></el-input>
          </el-form-item>
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeOTPModal">Cancel</el-button>
        <el-button v-if="tab1" :disabled="otpForm.type === ''" type="primary" @click="confirmRepurchase1(selected_transaction)">Next</el-button>
        <el-button v-else :disabled="otpForm.otp === '' || otpForm.reason === ''" type="primary" @click="verifyOTPRepurchase(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
      <!-- COOKIES FLOW-->
       <el-dialog :visible.sync="otpModalVisibleRepurchaseCookies" title="Input Reason" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeOTPModal">Cancel</el-button>
        <el-button :disabled="otpForm.reason === ''" type="primary" @click="verifyOTPRepurchaseCookiesTest(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
      <b-modal size="md" v-model="displayRefundRequest" title="Attachment" hide-footer width="70%" @hide="closeModalDipayManual()">
        <el-steps :active="current_step" finish-status="success">
          <el-step title="Attachment Data">
            <template slot="icon"><font-awesome-icon icon="building"/></template>
          </el-step>
          <el-step title="Confirmation">
            <template slot="icon"><font-awesome-icon icon="user"/></template>
          </el-step>
        </el-steps>
        <template v-if="current_step == 0">
          <b-form-group label="Upload Attachment">
            <b-form-file
              v-model="selected_file.file_image"
              :state="Boolean(selected_file.file_image)"
              class="mt-2 border-1 p-2"
              accept=".png, .jpg, .jpeg"
              @input="onChangeFile($event, 'file_image')"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <span class="text-danger" v-if="!selected_file.file_image" >Attachment is required</span>
          </b-form-group>
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button  :disabled="!selected_file.file_image"  @click="handlerStep(current_step)" class="mr-2 ml-2 float-right" size="small" type="primary">Next</el-button>
                <el-button @click="closeModalDipayManual()" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
          <template v-if="current_step == 1">
          <b-form-group label="Issue">
            <b-input v-model="form.issue" placeholder="Input Issue" type="text" />
          </b-form-group>
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button  :disabled="!form.issue"  @click="confirmRefundManual()" class="mr-2 ml-2 float-right" size="small" type="primary">Submit</el-button>
                <el-button @click="closeModalDipayManual()" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
      <div v-if="dialogVisibleOtpRepurchase" class="overlay"></div>
      <div v-if="dialogVisibleOtp" class="overlay"></div>
      <div v-if="otpModalVisibleRepurchase" class="overlay"></div>
      <div v-if="otpModalVisibleRepurchaseCookies" class="overlay"></div>
      <div v-if="otpModalVisibleCookies" class="overlay"></div>
      <div v-if="otpModalVisible" class="overlay"></div>
      <b-collapse id="check-dipay-status" v-model="collapseOpened" :duration="300">
        <b-card>
          <div v-if="loadingDipayStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="table-responsive">
            <p> Dipay Status </p>
            <table class="table table-vcenter w-full">
              <thead>
                <tr>
                  <th>Amount Value</th>
                  <th>Response Code</th>
                  <th>Original Reference No</th>
                  <th>Original Partner Reference_no</th>
                  <th>Transaction Status Desc</th>
                </tr>
              </thead>
              <tbody v-if="data_dipay">
                <tr>
                  <td>{{ formatCurrency(data_dipay.amount?.value) }}</td>
                  <td>{{ data_dipay.response_code }}</td>
                  <td>{{ data_dipay.original_reference_no }}</td>
                  <td>{{ data_dipay.original_partner_reference_no }}</td>
                  <td>
                    <span size="xs" v-if="data_dipay.transaction_status_desc === 'Success'" class="tag tag-success">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Failed'" class="tag tag-danger">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Process'" class="tag tag-info">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Waiting Payment'" class="tag tag-warning">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Refund Success' || 'Refunded'" class="tag tag-cyan">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else>{{ data_dipay.transaction_status_desc }}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div> 
        </b-card>
      </b-collapse>
      <br>
      <div class="table-container">
        <div class="table-responsive">
          <table v-if="!loading_detail" class="table table-vcenter w-full">
            <thead>
              <tr>
                <th width="15%">Product Name</th>
                <th>Type</th>
                <th>Destination Number</th>
                <th>Vendor</th>
                <th>Qty</th>
                <th>Base Price</th>
                <th>Sell Price</th>
                <th>Ref. Number</th>
                <th>Total</th>
                <th>Status</th>
                <th>Action Product</th>
                <th v-if="currentRole">Action Repurchase </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in selected_transaction.details" :key="i">
                <td>{{ item.product.name }}</td>
                <td>{{ item.source_type_str }}</td>
                <td>{{ item.hp || "-" }}</td>
                <td>{{ item.vendor ? item.vendor.vendor_name : "-" }}</td>
                <td>{{ item.product.quantity }}</td>
                <td>{{ formatCurrency(item.product.base_price) }}</td>
                <td>{{ item.product.sell_price }}</td>
                <td>{{ item.ref_vendor }}</td>
                <td> {{ item.product.total_price }} </td>
                <td>
                  <el-popover v-if="item.order_detail_status == 0 && item.order_detail_message" placement="left" width="200"
                    trigger="hover" :content="item.order_detail_message">
                    <span slot="reference" class="tag" :class="item.status_color">{{ item.status_str }}</span>
                  </el-popover>
                  <span v-else class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
                <td> 
                  <div>
                    <!-- <el-button :disabled="item.product_vendor !== 1" @click="checkProductStatus()"  v-b-toggle.product-status class="btn-outline-primary" size="small"> <i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button> -->
                    <!-- <el-button :disabled="(item.product_vendor !== 1 || item.product_vendor !== 4)" @click="toggleCollapseProduct(item.id)" class="btn-outline-primary" size="small"> <i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button> -->
                    <el-button :disabled="isButtonDisabled(item.product_vendor)" @click="toggleCollapseProduct(item.id)" class="btn-outline-primary" size="small"> <i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button>
                      <div v-if="!collapseOpenedProduct" class="tooltip show tooltip-inner tooltip-container" align="center" role="tooltip">
                        <div class="tooltip-arrow"></div>
                        <div class="tooltip-inner">
                            Click this
                          <i class="el-icon-top"></i>
                          <i class="el-icon-top"></i>
                        </div>
                      </div>
                  </div>
                </td>
                <td>
                  <!-- <el-button :disabled="!(item.source_type_str === 'Voucher' && item.vendor.vendor_name === 'Mobile Pulsa' && item.vendor.vendor_name === 'Ultra Voucher' && item.product_vendor === 1 && isSameMonth(selected_transaction.created_at_str)) || item.status_str === 'Success' && selected_transaction.status_str === 'Success' || !isBothCollapseOpened" @click="confirmRepurchase()" size="small" type="primary"> Repurchase </el-button> -->
                  <!-- NORMAL FLOW -->
                  <el-button :disabled="isButtonDisabledd(item)" v-if="!hasOtpCookie && currentRole" @click="confirmRepurchase()" size="small" type="primary"> Repurchase </el-button>
                  <!-- COOKIES FLOW -->
                  <el-button :disabled="isButtonDisabledd(item)" v-if="hasOtpCookie && currentRole" @click="confirmRepurchaseCookies()" size="small" type="primary"> Repurchase </el-button>
                </td>
               </tr>
               <tr>
                <td colspan="13">
                  <b-collapse id="product-status" v-model="collapseOpenedProduct">
                    <b-card>
                      <br>
                      <div v-if="loadingProductStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div v-else>
                        <p> Product Status </p>
                        <div class="table-responsive">
                          <table class="table table-vcenter w-full">
                            <thead>
                              <tr>
                                <th>Ref ID</th>
                                <th>Price</th>
                                <th>Balance</th>
                                <th>Transaction ID</th>
                                <th>RC</th>
                                <th>Code</th>
                                <th>HP</th>
                                <th>Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in product_status" :key="item.id">
                                <td>{{ item.ref_id }}</td>
                                <td>{{ formatCurrency(item.price) }}</td>
                                <td>{{ item.balance }}</td>
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.rc }}</td>
                                <td>{{ item.code }}</td>
                                <td>{{ item.hp }}</td>
                                <td>
                                  <span size="xs" v-if="item.message === 'SUCCESS'" class="tag tag-success">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'FAILED'" class="tag tag-danger">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'PROCESS'" class="tag tag-info">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'WAITING PAYMENT'" class="tag tag-warning">{{ item.message }}</span>
                                  <span v-else>{{ item.message }}</span> 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- <el-empty description="No data found" v-if="product_status.length === 0" :image="emptyStateImage"></el-empty> -->
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>
                </td>
               </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8"><i>Admin Fee</i></td>
                <td>{{ selected_transaction.admin_fee_str }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(item, i) in selected_transaction.additional_data" :key="i">
                <td colspan="8"><i>{{ item.additional_name }}</i></td>
                <td><i>{{ item.additional_fee_str }}</i></td>
              </tr>
              <tr v-for="(item, i) in selected_transaction.detail_discount" :key="i">
                <td colspan="8"><i>{{ item.promo_name }}</i></td>
                <td><i>-{{ item.promo_discount_str }}</i></td>
              </tr>
              <tr style="background-color: #f4f6fa; font-weight: bold;">
                <td colspan="8">Subtotal</td>
                <td>{{ selected_transaction.amount_str }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { REQUEST_REFUND_MANUAL, REQUEST_REFUND_ATTACHMENT } from '@/store/modules/refund_request';
import {  DETAIL_TRANSACTION, GET_REFUND_REPORT, DOWNLOAD_REFUND } from '@/store/modules/transactions';
import { STATUS_DIPAY, LOG_DIPAY, STATUS_PRODUCT, GET_REFUND_OTP, GET_REPURCHASE_OTP, REFUND_VERIFY, REPURCHASE_VERIFY } from '@/store/modules/app_user';
// import { REFUND_MOTION_PAY_VERIFY } from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import base64File from '@/library/base64File';
import imageCompress from '@/library/vue-image-compression';

export default {
  components: { Pagination },
  name: 'Refund',
  metaInfo: {
    title: 'Refund and Repurchase',
  },
  data() {
    return {
      tab1: true,
      data_attachment: [],
      fields: [
        { key: 'original_reference_no', label: 'Original Reference No' },
        { key: 'original_partner_reference_no', label: 'Original Partner Reference No' },
        { key: 'amount', label: 'Amount' },
        { key: 'transaction_status_desc', label: 'Status' },
      ],
      fieldsPurchase: [
        { key: 'vendor_id', label: 'Vendor ID' },
      ],
      collapseOpened: false,
      collapseOpenedProduct: false,
      selectOptions: [
        { name: 'Venteny Growth (ges)', company_id: 6667 },
        { name: 'PT Venteny Matahari Indonesia', company_id: 6 }
      ],
      company_id: 6667,
      selectedOption: '',
      otpModalVisible: false,
      otpModalVisibleRepurchase: false,
      otpForm: {
        otp: '',
        reason: '',
        type: 'sms',
        selectedOption: ''
      },
      form: {
        issue: '',
        file_image: '',
      },
      current_step: 0,
      user_id: null,
      showDetailTransaction: false,
      showSearch: true,
      search_options: [
        { value: 'invoice_number', text: 'Invoice Number' },
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      type_options: [
        { value: 'sms', text: 'SMS' },
        { value: 'email', text: 'Email' },
      ],
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      // data_motionpay: [],
      isLoading: true,
      loading_detail: false,
      loadingProductStatus: false,
      loadingDipayStatus: false,
      loadingDipayLog: false,
      displayRefundRequest: false,
      displayRefundManual: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_transaction: {
        user: {},
      },
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'invoice_number',
        search_keyword: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
        payment_vendor_filter: 'dipay',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      summary_vendor: [],
      status_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Waiting Payment',
          value: 1,
        },
        {
          label: 'Process',
          value: 2,
        },
        {
          label: 'Success',
          value: 3,
        },
        {
          label: 'Expired',
          value: 4,
        },
        {
          label: 'Failed',
          value: 5,
        },
      ],
      selected_file: {
        file_image: null,
        file_image_base64: '',
      },
      payment_vendor_filter_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Qris',
          value: 'qris',
        },
        {
          label: 'Espay',
          value: 'espay',
        },
        {
          label: 'Dipay',
          value: 'dipay',
        },
        {
          label: 'M-bayar',
          value: 'e2pay',
        },
        {
          label: 'Xendit',
          value: 'xendit',
        },
        {
          label: 'Motion Pay',
          value: 'motionpay',
        },
      ],
      selected_transaction: {  },
      data_verify_repurchase: [],
      data_dipay: [],
      data_Verify:[],
      log_dipay: [],
      product_status: [],
      data_OTP: [],
      data_OTP_repurchase: [],
      loadingButton: false,
      // PIN
      dialogVisibleOtp: false,
      dialogVisibleOtpRepurchase: false,
      otpModalVisibleRepurchaseCookies: false,
      otpModalVisibleCookies: false
    };
  },
  async created() {
    this.user_id = localStorage.getItem('user_id');
  },
  async mounted() {
    const loader = this.$loading.show();
    this.page = 1;
    this.search.action = '';
    const today = moment().format('YYYY-MM-DD');
    this.search.date = [today, today];
    this.search.start_date = today;
    this.search.end_date = today;
    this.search.payment_vendor_filter = 'dipay', 
    this.search.status_filter = 3;
    await this.getList();
    loader.hide();
    this.startTimer();
    this.startRefundTimer(); 
  },
  computed: {
    currentRole() {
      const roleName = this.$store.getters.current_role?.role?.name;
      return roleName === "Super Admin" || roleName === "Growth & CS";
    },
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    // new
    formattedData() {
      return this.data_dipay.map(item => ({
        original_reference_no: item.data.original_reference_no,
        original_partner_reference_no: item.data.original_partner_reference_no,
        amount: this.formatCurrency(item.data.amount?.value),
        transaction_status_desc: item.data.transaction_status_desc,
      }));
    },
    formattedDataLog() {
      return this.log_dipay.map(item => ({
        vendor_id: item.data.vendor_id,
      }));
    },
    isBothCollapseOpened() {
      return this.collapseOpened && this.collapseOpenedProduct;
    },
    hasOtpCookie() {
      const repurchaseOtp = this.getCookie('repurchase_otp');
      const repurchaseToken = this.getCookie('repurchase_token');
      const repurchaseCompanyId = this.getCookie('repurchase_company_id');
      const repurchaseSavedAt = this.getCookie('repurchase_saved_at');
      return repurchaseOtp && repurchaseToken && repurchaseCompanyId && repurchaseSavedAt;
    },
    hasRefundCookies() {
      const refundOtp = this.getCookie('refund_otp');
      const refundToken = this.getCookie('refund_token');
      const refundCompanyId = this.getCookie('refund_company_id');
      const refundSavedAt = this.getCookie('refund_saved_at');
      return refundOtp && refundToken && refundCompanyId && refundSavedAt;
    }
  },
  methods: {
    confirmCookiesRefund () {
      this.otpModalVisibleCookies = true
    },
    handlerStep(step) {
      if (step === 0) {
        this.confirmRefundAttachment(); 
        this.current_step = 1;
      }
    },
    showModal() {
      this.displayRefundRequest = true
    },
    async confirmRefundManual(selected_transaction) {
      try {
        const response = await this.$store.dispatch(REQUEST_REFUND_MANUAL, {
          data: {
            order_id: this.selected_transaction.id, 
            issue: this.form.issue, 
            attachment_ids: [this.data_attachment],
          }
        });
        this.displayRefundManual =  false
        this.displayRefundRequest =  false
        this.showDetailTransaction = false
        this.closeModalDipayManual()
        this.$message.success('Refund Manual Request Submitted Successfully');
        console.log(response); 
      } catch (error) {
        this.$message.error('error');
        console.error(error);
      }
    },
    showModalAttachment() {
      this.displayRefundRequest = true
    },
    confirmRepurchaseCookies() {
      this.otpModalVisibleRepurchaseCookies = true
    },
    async confirmRefundAttachment() {
      try {
        const response = await this.$store.dispatch(REQUEST_REFUND_ATTACHMENT, {
          data: {
            attachment_base64: this.form.file_image,
          }
        });
        this.data_attachment = response.data.id; 
        if (this.data_attachment) {
        }
      } catch (error) {
        this.$message.error('please input attachment');
        console.error(error);
      }
    },

    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    handlerDownloadBtn() {
      this.search.action = 'download';
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(this.search.action === 'download' ? DOWNLOAD_REFUND : GET_REFUND_REPORT, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        ...this.search,
      }).then((result) => {
        if (this.search.action === 'download') {
            const rows = result.data.data.rows;
            if (rows && Array.isArray(rows) && rows.length > 0) {
            const csvContent = [
              "Transaction Date", 
              "Invoice Number", 
              "User Name", 
              "User Email", 
              "Amount", 
              "Discount",
              "Admin Fee",
              "Payment Vendor", 
              "Payment Method", 
              "Status", 
            ].join(',') + '\n' +
              rows.map(row => {
                return [
                  row.created_at,
                  row.invoice_number,
                  row.user_full_name,
                  row.user_email,
                  row.total_transaction,
                  row.discount,
                  row.admin_fee,
                  row.payment_vendor,
                  row.pm_payment_name,
                  row.status === 3 ? "Sukses" : row.status, 
                ].join(',');
              }).join('\n');
    
              const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
              const anchor = document.createElement('a');
              anchor.href = csvData;
              anchor.target = '_blank';
              anchor.download =  `Refund-Repurchase-Report-${this.search.start_date}-${this.search.end_date}.csv`;
              anchor.click();
            } else {
                console.error('No data found in rows array.');
            }
        } else {
            const { rows, count } = this.$store.getters.refund_report;
            this.total_rows = count || 0;
            this.list = rows;
        } 
        }).finally(() => {
        this.isLoading = false;
        });
    },
    async viewDetailTransaction(item) {
      try {
        this.collapseOpened = false,
        this.collapseOpenedProduct = false,
        this.selected_transaction = item;
        this.showDetailTransaction = true;
        this.loading_detail = true;
        await this.$store.dispatch(DETAIL_TRANSACTION, item.invoice_number);
        this.transactionDetails = this.$store.getters.trx.details.map((v) => {
          const payment_ref = v.order?.payment_ref || '-';
          return {
            order_id: v.order_id,
            source_type: v.source_type,
            product_vendor: v.product_vendor,
            // new
            id: v.id,
            payment_ref: v.payment_ref
          };
        });
        this.selected_transaction.details = this.$store.getters.trx.details.map((v) => {
          const {
            detail_product, quantity, sell_price, total_price, v_price, base_price, transaction_qri, amount, insurance_order, voucher, order
          } = v;
          v.payment_ref = order.payment_ref;
          v.source_type_str = upperFirst(v.source_type);
          if (v.source_type === 'v_price') {
            v.source_type_str = 'PPOB';
          } else if (v.source_type === 'special_program') {
            v.source_type_str = 'Spesial Program';
          } else if (v.source_type === 'lms') {
            v.source_type_str = 'Academy';
          }
          v.product = {};
          if (v.source_type === 'voucher') {
            v.product = {
              name: detail_product ? detail_product?.voucher_name : voucher.voucher_name,
              base_price: detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price ? `Rp. ${detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price.toLocaleString('id-ID')}` : detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'v_price') {
            v.product = {
              name: v_price.v_price_type ? [v_price.v_price_type, v_price.nominal].join(' ') : v_price.product_code,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
            if (v_price.vendor_id.toString() === '8') {
              if (v.detail_product.provider && v.detail_product.nominal) {
                v.product.name = [v.detail_product.provider, v.detail_product.nominal].join(' ');
              } else {
                v.product.name = v_price.nominal;
              }
            }
          } else if (v.source_type === 'academic') {
            v.product = {
              name: detail_product.academic_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
            // name: transaction_qri.transaction_qris_name || '',
          } else if (v.source_type === 'qris') {
            v.product = {
              name: transaction_qri && transaction_qri.transaction_qris_name ? transaction_qri.transaction_qris_name : '',
              base_price: '-',
              sell_price: '-',
              quantity: 1,
              total_price: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : amount,
            };
          } else if (v.source_type === 'insurance') {
            v.product = {
              name: insurance_order.insurance_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'special_program') {
            v.product = {
              name: detail_product.productName,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'lms') {
            v.product = {
              name: detail_product.name,
              base_price,
              base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
              sell_price,
              sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
            };
          }
          v.status_str = 'Failed';
          v.status_color = 'tag-danger';

          if (v.order_detail_status === 1) {
            v.status_str = 'Process';
            v.status_color = 'tag-info';
            if (this.selected_transaction.status === 1) {
              v.status_str = 'Waiting payment';
              v.status_color = 'tag-warning';
            }
          } else if (v.order_detail_status === 2) {
            v.status_str = 'Success';
            v.status_color = 'tag-success';
          } else if (v.order_detail_status === 3) {
            v.status_str = 'Refund';
            v.status_color = 'tag-teal';
          }
          return v;
        });
      } catch (error) {
      } finally {
        this.loading_detail = false;
      }
    },
    showUserData(data, value) {
      // return data?.user?.[value];
      return data?.user?.[value];
    },
    async checkDipayStatusAndLog(selected_transaction) {
      // first method
      await this.checkDipayStatus(selected_transaction);
      //  second method
      await this.checkDipayLog(selected_transaction);
    },
    async checkDipayStatus(selected_transaction) {
      this.loadingDipayStatus = true
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        this.data_dipay = [];
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by
        };
        const response = await this.$store.dispatch(STATUS_DIPAY, requestBody);
        // console.log("Response from STATUS_DIPAY:", response);
        if (response.data.transaction_status_desc === 'Success') {
          response.data.dipay_status_color = 'tag-success';
        } else if (response.data.transaction_status_desc === 'Refund Success' || response.data.transaction_status_desc === 'Refunded') {
          response.data.dipay_status_color = 'tag-teal';
        } else if (response.data.transaction_status_desc === 'Waiting payment' || response.data.transaction_status_desc === 'Process') {
          response.data.dipay_status_color = 'tag-warning';
        } else if (response.data.transaction_status_desc === 'Failed') {
          response.data.dipay_status_color = 'tag-danger';
        }
        this.data_dipay = response.data;
        // console.log('NIH DATA DIPAY', this.data_dipay)
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDipayStatus = false
      }
    },
    async checkDipayLog(selected_transaction) {
      this.loadingDipayLog = true
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by
        };
        const response = await this.$store.dispatch(LOG_DIPAY, requestBody);
        this.log_dipay = response
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDipayLog = false
      }
    },
    async checkProductStatus(id) {
      this.loadingProductStatus = true
      this.product_status = [];
      try {
        //  transactionDetails ga kosong
        if (!this.transactionDetails || this.transactionDetails.length === 0) {
          return;
        }
        //  detail transaksi pertama dari array
        const firstTransactionDetails = this.transactionDetails[0];
        // get order_id, source_type, dan product_vendor from firstTransactionDetails
        const { order_id, source_type, product_vendor } = firstTransactionDetails;
        //  order_id, source_type, dan product_vendor ad?
        if (!order_id || !source_type || !product_vendor) {
          return;
        }
        // req body
        const requestBody = {
          data: {
            order_id,
            source_type,
          },
          product_vendor,
        };
        const response = await this.$store.dispatch(STATUS_PRODUCT, requestBody);
        this.product_status = response.data.rows;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingProductStatus = false; 
      }
    },
    // the same month
     isSameMonth(created_at_str) {
        const createdDate = new Date(created_at_str);
        const createdMonth = createdDate.getMonth();
        const currentMonth = new Date().getMonth();
        return createdMonth === currentMonth;
    },
    // format
    formatCurrency(value) {
      if (value) {
        const hasDecimalPart = value % 1 !== 0;
        const formattedIntegerPart = Math.floor(value).toLocaleString('id-ID');
        const formattedValue = `Rp. ${formattedIntegerPart}`;
        return hasDecimalPart ? `Rp. ${value.toLocaleString('id-ID')}` : formattedValue;
      } else {
        return value;
      }
    },
    // in a month FIXXXX
    isRefundDisabled(selected_transaction) {
      if (!selected_transaction || !selected_transaction.created_at_str || selected_transaction.status === 7) {
        return true;
      }
      if (!selected_transaction.details?.[0]?.payment_ref) {
        return true; 
      }
      const currentTime = new Date(); 
      const createdAtTime = new Date(selected_transaction.created_at_str);
      const isRefundSuccess = selected_transaction.status_str === "Refund Success";
      const isRefundRequested = selected_transaction.status_str === "Refund Requested";
      const isSuccess = selected_transaction.status_str === "Success";
      const validVendors = ["Dipay", "Motion Pay", "Espay"];
      const isValidVendor = validVendors.includes(selected_transaction.payment_vendor_str);
      // console.log("date skrng:", currentTime.toLocaleString());
      // console.log("trx date:", createdAtTime.toLocaleString());
      // console.log("trx status:", selected_transaction.status_str);
      // console.log("p vendor:", selected_transaction.payment_vendor_str);
      // console.log("valid vendr?", isValidVendor);
      // new req weekend
      const createdHour = currentTime.getHours();
      const dayOfWeek = currentTime.getDay(); // 0 -> sunday, 1 -> monday, etc
      const isWeekend = (dayOfWeek === 0 || dayOfWeek === 6); // saturday / sunday
      const isFridayAfternoon = dayOfWeek === 5 && createdHour >= 14; // > 14:00 on friday
      const isMondayMorning = dayOfWeek === 1 && currentTime.getHours() < 9; // < 09:00 on moday
      // console.log("day brp:", dayOfWeek);
      // console.log("jam:", createdHour);
      // console.log("weekend ga?", isWeekend);
      // console.log("jumat sore??", isFridayAfternoon);
      // console.log("minggu pagi?", isMondayMorning);
      // weekend ?
      if (isWeekend || isFridayAfternoon || isMondayMorning) {
        if (isValidVendor && isSuccess) {
          return false; // Refund is enabled for weekend transactions or during Friday 14:01 - Monday 08:59
        }
      }
      // !weekend
      let disableTime = false;
      if (isValidVendor && isSuccess) {
        //if < 14:00 on the same day 
        if (createdHour < 14) {
          return false; 
        } else {
          // if > 14:00 on the same day 
          // disableTime = true;
          disableTime = false;
        }
      }
      const timeDifferenceMilliseconds = currentTime - createdAtTime;
      const isWithin30Minutes = timeDifferenceMilliseconds < 30 * 60 * 1000;
      console.log("time (ms):", timeDifferenceMilliseconds);
      // console.log("lewat 30 mnt?", isWithin30Minutes);
      return (
        !isValidVendor || 
        isRefundSuccess || 
        (!isSuccess && !isRefundRequested) || 
        (selected_transaction.details &&
          selected_transaction.details.some(
            detail =>
              detail.order_detail_status !== 0 &&
              detail.order_detail_status !== 1 &&
              detail.order_detail_status !== null &&
              detail.vendor.vendor_name !== 'Mobile Pulsa' &&
              detail.vendor.vendor_name !== 'Ultra Voucher'
          )
        ) ||
        // isWithin30Minutes ||
        disableTime 
      );
    },
    refund() {
      this.dialogVisibleOtp = true;
    },
    // NORMAL FLOW REFUND
    async confirm() {
        this.otpModalVisible = true;
        this.dialogVisibleOtp = false;
        this.tab1 = true;
    },
    async confirm1() {
      try {
        const response = await this.$store.dispatch(GET_REFUND_OTP, { company_id: this.company_id, type: this.otpForm.type });
        this.data_OTP = response.data;
        const hexToken = this.data_OTP.token;
        const decodedToken = this.hexToString(hexToken);
        const token = decodedToken.slice(0, -6).replace(/:/g, ''); 
        const companyId = this.data_OTP.company_id; 
        // const expirationTime = new Date(new Date().getTime() + 3600 * 1000); 
        const now = new Date();
        const currentHour = now.getHours();
        const remainingHours = 24 - currentHour;
        const expirationTime = new Date(now.getTime() + remainingHours * 60 * 60 * 1000);
        document.cookie = `refund_token=${token}; expires=${expirationTime.toUTCString()}; path=/`;
        document.cookie = `refund_company_id=${companyId}; expires=${expirationTime.toUTCString()}; path=/`;
        document.cookie = `refund_saved_at=${Date.now()}; expires=${expirationTime.toUTCString()}; path=/`; 
        this.otpModalVisible = true;
        this.dialogVisibleOtp = false;
        this.startRefundTimer(); 
        this.tab1 = false; 
      } catch (error) {
        this.$message.error('Failed to get OTP');
        this.clearExistingCookies();
        console.error(error);
      }
    },
    clearExistingCookies() {
      const cookiesToClear = ['refund_token', 'refund_otp', 'refund_company_id', 'refund_saved_at'];
      cookiesToClear.forEach(cookieName => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    },
    startRefundTimer() {
      const savedAtCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('refund_saved_at='));
      if (savedAtCookie) {
        const savedAtTimestamp = parseInt(savedAtCookie.split('=')[1], 10);
        const currentTime = Date.now();
        const elapsedTime = (currentTime - savedAtTimestamp) / 1000; 
        const timeLeft = 3600 - elapsedTime;
        if (timeLeft > 0) {
          const timer = setInterval(() => {
            const updatedTimeLeft = timeLeft - (Math.floor((Date.now() - savedAtTimestamp) / 1000)); 
            if (updatedTimeLeft <= 0) {
              clearInterval(timer);
              this.clearRefundCookies(); 
            } else {
              const minutes = Math.floor(updatedTimeLeft / 60);
              const seconds = Math.floor(updatedTimeLeft % 60);
            }
          }, 1000); 
        } else {
          this.clearRefundCookies(); 
        }
      } else {
      }
    },
    clearRefundCookies() {
      document.cookie = "refund_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "refund_otp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "refund_company_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "refund_saved_at=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    closeOTPModalCookies() {
      this.otpModalVisible = false;
      this.otpModalVisibleRepurchaseCookies =  false
      this.otpModalVisibleCookies =  false
    },
    closeOTPModal() {
      this.$refs.otpForm.resetFields();
      this.otpModalVisible = false;
      this.otpModalVisibleRepurchase =  false
      this.tab1 = true;
    },
    closeModalDipay() {
      this.displayRefundRequest =  false
    },
    closeModalDipayManual() {
      this.displayRefundManual =  false
      this.displayRefundRequest =  false
      this.form.issue = '',
      this.selected_file.file_image = ''
    },
    async verifyOTP(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const tokenRefundFromCookie = this.getCookie('refund_token');
        const requestBody = {
          invoice_number: selected_transaction.invoice_number,
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          token: tokenRefundFromCookie || "",
          otp: this.otpForm.otp,
          user_id: this.user_id,
        };
        // const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        const now = new Date();
        const currentHour = now.getHours();
        const remainingHours = 24 - currentHour;
        const expirationTime = new Date(now.getTime() + remainingHours * 60 * 60 * 1000);
        document.cookie = `refund_otp=${this.otpForm.otp}; expires=${expirationTime.toUTCString()}; path=/`;
        const response = await this.$store.dispatch(REFUND_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.otpForm.type = '';
        this.closeOTPModal();
        this.$message.success('Product successfully refunded');
        this.data_Verify = response;
      } catch (error) {
        const errorResponse = error.response?.data?.error;
        if (errorResponse && errorResponse.title) {
          this.$message.error(errorResponse.title);
        } else {
          this.$message.error('An error occurred while processing your request.');
        }
        this.closeOTPModal();
        this.clearExistingCookies();
        console.error(error);
      }
    },
    async verifyOTPRefundCookies(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const refundToken = this.getCookie('refund_token');
        const refundOtp = this.getCookie('refund_otp');
        if (!refundToken || !refundOtp) {
          this.$message.error('Token or OTP Refund not found in cookies');
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number,
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          token: refundToken || "",
          otp: refundOtp || 666666,
          user_id: this.user_id
        };
        const response = await this.$store.dispatch(REFUND_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.otpForm.type = '';
        this.closeOTPModalCookies();
        this.$message.success('Product successfully refunded');
        this.data_Verify = response;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          const errorMessage = error.response.data.error;
          if (errorMessage.code === 1019 && errorMessage.title === 'invalid_otp') {
            this.$message.error('OTP is not valid');
          } else if (errorMessage.code === 400 && errorMessage.title.includes('Transaction Not Found')) {
            this.$message.error('The transaction has been successfully refunded');
          } else {
            this.$message.error('Error');
          }
        } else {
          this.$message.error('Error');
        }
        this.closeOTPModalCookies();
        console.error(error);
      }
    },
    formatOTP() {
      this.otpForm.otp = this.otpForm.otp.replace(/\D/g, '');
    },
    repurchase() {
      this.dialogVisibleOtpRepurchase = true;
    },
    async confirmRepurchase() {
        this.otpModalVisibleRepurchase = true;
        this.dialogVisibleOtpRepurchase = false;
        this.tab1 = true; 
    },
     async confirmRepurchase1() {
      try {
        const response = await this.$store.dispatch(GET_REPURCHASE_OTP, { company_id: this.company_id, type: this.otpForm.type});
        this.data_OTP_repurchase = response.data;
        const hexToken = this.data_OTP_repurchase.token; 
        const decodedToken = this.hexToString(hexToken);
        // const token = decodedToken.slice(0, -6); 
        const token = decodedToken.slice(0, -6).replace(/:/g, '');
        const companyId = this.data_OTP_repurchase.company_id; 
        const now = new Date();
        const currentHour = now.getHours();
        const remainingHours = 24 - currentHour;
        const expirationTime = new Date(now.getTime() + remainingHours * 60 * 60 * 1000);
        document.cookie = `repurchase_token=${token}; expires=${expirationTime.toUTCString()}; path=/`;
        document.cookie = `repurchase_company_id=${companyId}; expires=${expirationTime.toUTCString()}; path=/`;
        document.cookie = `repurchase_saved_at=${Date.now()}; expires=${expirationTime.toUTCString()}; path=/`; 
        this.otpModalVisibleRepurchase = true;
        this.dialogVisibleOtpRepurchase = false;
        this.tab1 = false; 
      } catch (error) {
        this.$message.error('Failed to get OTP');
        this.clearExistingCookiesRepurchase();
        console.error(error);
      }
    },
    clearExistingCookiesRepurchase() {
      const cookiesToClear = ['repurchase_token', 'repurchase_otp', 'repurchase_company_id', 'repurchase_saved_at'];
      cookiesToClear.forEach(cookieName => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    },
    startTimer() {
      const savedAtCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('saved_at='));

      if (savedAtCookie) {
        const savedAtTimestamp = parseInt(savedAtCookie.split('=')[1], 10);
        const currentTime = Date.now();
        const elapsedTime = (currentTime - savedAtTimestamp) / 1000; 
        const timeLeft = 3600 - elapsedTime; 
        if (timeLeft > 0) {
          const timer = setInterval(() => {
            const updatedTimeLeft = timeLeft - (Math.floor((Date.now() - savedAtTimestamp) / 1000)); 
            if (updatedTimeLeft <= 0) {
              clearInterval(timer);
              this.clearRepurchaseCookies(); 
            } else {
              const minutes = Math.floor(updatedTimeLeft / 60);
              const seconds = Math.floor(updatedTimeLeft % 60);
            }
          }, 1000); 
        } else {
          this.clearRepurchaseCookies(); 
        }
      } else {
      }
    },
    clearRepurchaseCookies() {
      document.cookie = "repurchase_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "otp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "company_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "saved_at=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    hexToString(hex) {
      let str = '';
      for (let i = 0; i < hex.length; i += 2) {
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
      return str;
    },
    async verifyOTPRepurchase(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const tokenFromCookie = this.getCookie('repurchase_token');
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          token: tokenFromCookie|| "",
          otp: this.otpForm.otp,
          user_id: this.user_id
        };
        // const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        const now = new Date();
        const currentHour = now.getHours();
        const remainingHours = 24 - currentHour;
        const expirationTime = new Date(now.getTime() + remainingHours * 60 * 60 * 1000);
        document.cookie = `repurchase_otp=${this.otpForm.otp}; expires=${expirationTime.toUTCString()}; path=/`;
        const response = await this.$store.dispatch(REPURCHASE_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.otpForm.type = '';
        this.closeOTPModal();
        this.$message.success('Product successfully repurchased');
        this.data_verify_repurchase = response;
      } catch (error) {
        const errorResponse = error.response ? error.response.data : {};
        const errorMessage = errorResponse.error ? errorResponse.error.message : 'Error';
        this.$message.error(errorMessage);
        this.closeOTPModal();
         this.clearExistingCookiesRepurchase();
        console.error(error);
      }
    },
    async verifyOTPRepurchaseCookiesTest(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const token = this.getCookie('repurchase_token');
        const otp = this.getCookie('repurchase_otp');
        if (!token || !otp) {
          this.$message.error('Token or OTP not found in cookies');
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number,
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          token: token || "",
          otp: otp || 666666,
          user_id: this.user_id
        };
        const response = await this.$store.dispatch(REPURCHASE_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.otpForm.type = '';
        this.$message.success('Product successfully repurchased');
        this.data_verify_repurchase = response;
        this.closeOTPModalCookies();
      } catch (error) {
        const errorResponse = error.response ? error.response.data : {};
        const errorMessage = errorResponse.error ? errorResponse.error.message : 'Error';
        this.$message.error(errorMessage);
        console.error(error);
        this.closeOTPModalCookies();
      }
    },
    decodeHexToken(hexToken) {
      const token = hexToken.slice(0, -6); 
      const otp = hexToken.slice(-6); 
      return token; 
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return '';  
    },
    toggleCollapse() {
      if (!this.collapseOpened) {
        if (this.selected_transaction) {
        this.checkDipayStatusAndLog(this.selected_transaction);
      }
        this.collapseOpened = true;
      } else {
        this.collapseOpened = false;
      }
    },
    toggleCollapseProduct(id) {
      if (!this.collapseOpenedProduct) {
        this.checkProductStatus(id);
        this.collapseOpenedProduct = true;
      } else {
        this.collapseOpenedProduct = false;
      }
    },
    isButtonDisabled(productVendor) {
      return productVendor !== 1 && productVendor !== 4;
    },
    isButtonDisabledd(item) {
    const createdDate = new Date(item.created_at_str);
    const currentTime = new Date();
    const timeDifference = (currentTime - createdDate) / (1000 * 60); 
    const isTimeExpired = timeDifference > 30;
    // console.log(' Repurchase Created Date:', createdDate);
    // console.log(' Repurchase Current Time:', currentTime);
    // console.log(' Repurchase Time Difference in Minutes:', timeDifference);
    // console.log(' Repurchase Is Time Expired (more than 30 minutes)?:', isTimeExpired);
    const isVoucher = item.source_type_str === 'Voucher';
    const isVendorMobilePulsa = item.vendor.vendor_name === 'Mobile Pulsa';
    const isVendorUltraVoucher = item.vendor.vendor_name === 'Ultra Voucher';
    const isProductVendor1 = item.product_vendor === 1;
    const isProductVendor4 = item.product_vendor === 4;
    const isSuccessStatus = item.status_str === 'Success' && this.selected_transaction.status_str === 'Success';
    // console.log(' Repurchase is Voucher Type:', isVoucher);
    // console.log(' Repurchase is Vendor Mobile Pulsa?:', isVendorMobilePulsa);
    // console.log(' Repurchase is Vendor UltraV oucher:', isVendorUltraVoucher);
    // console.log(' Repurchase is Product Vendornya 1?:', isProductVendor1);
    // console.log(' Repurchase is Product Vendornya 4?:', isProductVendor4);
    // console.log(' Repurchase is Success Status:', isSuccessStatus);
    const condition1 = !(
      (isVoucher && (isVendorMobilePulsa || isVendorUltraVoucher) && (isProductVendor1 || isProductVendor4)) ||
      isSuccessStatus || isTimeExpired
    );
    console.log(' Repurchase Condition 1:', condition1);
    return condition1 
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
/* del */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.buttonload {
  background-color: #006EA5; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px /* Set a font size */
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the overlay is above other elements */
}

.custom-table-collapse {
  background-color: rgba(198, 135, 68, 0.853); /* Example background color */
  /* Add more custom styling as needed */
}

.b-collapse-transition {
  transition: height 0.3s ease-in-out;
}

.tooltip-container {
  margin-top: 10px;
}

.tooltip-inner {
  color: #FA7F03;
  text-align: center;
  background-color: #000000;
  border-radius: 4px;
  font-size: 11px
}
</style>